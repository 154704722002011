import React from "react"
import { graphql } from "gatsby"

import Layout from "../../../components/layout"
import GroupTemplate from "../components/group-template"

const BokoaryPage = ({ data }) => {
  const creatures = data.allSanityCreature.nodes

  return (
    <Layout>
      <GroupTemplate
        name="Bokoary"
        creatures={creatures}
        imageUrl="https://media-waterdeep.cursecdn.com/avatars/thumbnails/7996/753/1000/1000/637115020139998917.jpeg"
        description="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sapiente modi hic dicta. Incidunt eius, voluptatibus voluptate ducimus exercitationem odio unde similique ut veniam nostrum, earum nesciunt maiores consectetur perspiciatis!"
      />
    </Layout>
  )
}

export default BokoaryPage

export const query = graphql`
  query {
    allSanityCreature(sort: { fields: [name], order: ASC }) {
      nodes {
        _key
        type {
          name
        }
        name
        slug {
          current
        }
        image {
          asset {
            fluid(maxWidth: 80) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
