import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { CreaturePreview } from "../../../types/Creature"

interface Props {
  name: string
  imageUrl: string
  description: string
  creatures: CreaturePreview[]
}

const GroupTemplate: React.FC<Props> = ({
  name,
  imageUrl,
  description,
  creatures,
}) => {
  // console.log("name", name)

  return (
    <div className="bg-texture overflow-hidden">
      <div className="relative max-w-6xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto text-base max-w-prose lg:max-w-none">
          <h3 className="text-base sm:text-lg text-gray-700 font-semibold tracking-wide uppercase">
            Denizen
          </h3>
          <h1 className="mt-2 text-4xl font-bold font-display tracking-tight text-primary sm:text-6xl">
            {name}
          </h1>
        </div>

        <div className="mt-8 lg:mt-3 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-2 aspect-h-3">
                  <img
                    src={imageUrl}
                    className="w-full h-full object-cover object-center"
                    alt="Whitney leaning against a railing on a downtown street"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg text-gray-500">
                Sagittis scelerisque nulla cursus in enim consectetur quam.
                Dictum urna sed consectetur neque tristique pellentesque.
                Blandit amet, sed aenean erat arcu morbi.
              </p>
            </div>
            <div className="mt-5 text-lg prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p>
                Sollicitudin tristique eros erat odio sed vitae, consequat
                turpis elementum. Lorem nibh vel, eget pretium arcu vitae. Eros
                eu viverra donec ut volutpat donec laoreet quam urna.
              </p>
              <p>
                Bibendum eu nulla feugiat justo, elit adipiscing. Ut tristique
                sit nisi lorem pulvinar. Urna, laoreet fusce nibh leo. Dictum et
                et et sit. Faucibus sed non gravida lectus dignissim imperdiet
                a.
              </p>
              <p>
                Dictum magnis risus phasellus vitae quam morbi. Quis lorem lorem
                arcu, metus, egestas netus cursus. In.
              </p>
              <ul>
                <li>Quis elit egestas venenatis mattis dignissim.</li>
                <li>
                  Cras cras lobortis vitae vivamus ultricies facilisis tempus.
                </li>
                <li>Orci in sit morbi dignissim metus diam arcu pretium.</li>
              </ul>
            </div>
          </div>
        </div>

        {creatures.length > 0 && (
          <div className="mt-6">
            <div className="flex flex-col">
              <h3 className="mt-2 text-3xl font-bold font-display tracking-tight text-primary sm:text-5xl">
                Browse {name}
              </h3>

              <div className="mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Size
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Alignment
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Environment
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="bg-white divide-y divide-gray-200">
                        {creatures.map(creature => (
                          <tr className="hover:bg-orange-50 cursor-pointer">
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                  <Image
                                    fluid={creature.image.asset.fluid}
                                    className="h-10 w-10 rounded-full border border-gray-200 shadow-sm"
                                    alt={`${creature.name} Avatar`}
                                  />
                                </div>
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    {creature.name}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    Infernal machine rebuild
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              Giant
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              Large
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              Chaotic Neutral
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              Mountain
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <Link
                                to={`/denizens/${creature.type}/${creature.slug.current}`}
                                className="text-primary hover:text-primary-light"
                              >
                                More
                              </Link>

                              {/* <Link
                                to={`/denizens/${creature.type.replace(
                                  /_/g,
                                  "-"
                                )}/${creature.slug.current}`}
                                className="text-primary hover:text-primary-light"
                              >
                                More
                              </Link> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default GroupTemplate
